import React, { useContext, useEffect } from 'react'
import Container from '../components/Container'
import { GlobalDispatchContext } from '../containers/GlobalContextProvider'
export default function piskotki(props) {
  const dispatch = useContext(GlobalDispatchContext)
  useEffect(() => {
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageEntering' })
  }, [])
  return (
    <div style={{ padding: '8rem 0' }}>
      <Container>
        {props.pageContext.locale === 'sl' ? sloCookiesContent() : enCookiesContent()}
      </Container>
    </div>
  )
}

function sloCookiesContent() {
  return (
    <p>
      <br />
      <br /> Da bi vam olajšali uporabo naše spletne strani in jo naredili bolj prijetno,
      uporabljamo »piškotke«. Piškotki so majhne podatkovne enote, ki nam omogočajo primerjavo novih
      in predhodnih obiskovalcev, razumevanje, kako uporabljajo našo stran, in ki nam posredujejo
      podatke, s katerimi lahko naredimo prihodnji obisk spletne strani bolj prijeten in učinkovit.
      Piškotki ne beležijo nobenih osebnih podatkov o uporabniku in ne shranjujejo informacij, ki bi
      vas lahko identificirale. Če ne želite uporabljati piškotkov, nastavite svoj računalnik tako,
      da izbriše vse piškotke spletnih strani in/ali vas opozori, preden se piškotek shrani na disk.
      Če ne želite spremeniti uporabe piškotkov, preprosto nadaljujte na spletno stran.
      <br />
      <br /> Za več informacij o piškotkih in njihovem vplivu na vas in vaše brskanje po spletu
      obiščite spletno stran www.aboutcookies.org.
      <br />
      <br /> VRSTE PIŠKOTKOV, KI JIH UPORABLJAMO
      <br />
      <br />
      <strong>Obvezni piškotki</strong>
      <br /> Ti piškotki so nujni za premikanje po spletni strani in uporabo njenih možnosti, kot je
      dostop do varnih območij spletne strani. Brez teh piškotkov ni možno zagotoviti storitev, ki
      ste jih zahtevali, na primer obrazec za nagradno igro ipd.
      <br />
      <br />
      <strong>Izvedbeni piškotki </strong>
      <br /> Ti piškotki zbirajo informacije o načinu uporabe spletne strani, na primer katere
      strani uporabniki najpogosteje obiščejo, in ali se na spletnih straneh pojavijo sporočila o
      napaki. Ti piškotki ne zbirajo informacij, ki bi lahko identificirale uporabnika. Vse zbrane
      informacije so združene in zato anonimne. Ti piškotki se uporabljajo samo za izboljšanje
      delovanja spletne strani. 
      <br /> Z uporabo naše spletne strani se strinjate, da na vašo napravo namestimo to vrsto
      piškotkov.
      <br />
      <br />
      <strong>Funkcionalni piškotki </strong>
      <br /> Ti piškotki omogočajo, da si spletna stran zapomni vaše izbrane možnosti (na primer
      vaše uporabniško ime, jezik ali regijo, kjer se nahajate) in vam ponudi izboljšane, bolj
      osebne možnosti. Ti piškotki si lahko zapomnijo tudi spremembe velikosti črk in pisave ter
      drugih delov spletne strani, ki jih lahko prilagodite. Uporabni so tudi za zagotavljanje
      storitev, ki ste jih zahtevali, kot je ogled videoposnetka ali komentiranje na blogu.
      Informacije, ki jih zbirajo ti piškotki, so lahko anonimne in ne sledijo vašemu brskanju po
      drugih spletnih straneh. 
      <br /> Z uporabo naše spletne strani se strinjate, da na vašo napravo namestimo to vrsto
      piškotkov.
      <br />
      <br />
      <strong>Kako upravljati piškotke na osebnem računalniku </strong>
      <br /> Če želite omogočiti piškotke naše spletne strani, sledite spodnjim korakom.
      <br />
      <br />
      <strong>Google Chrome</strong>
      <br /> 1. Kliknite »Orodja« na vrhu vašega brskalnika in izberite »Nastavitve«.
      <br /> 2. Kliknite »Prikaži dodatne nastavitve«, se pomaknite do razdelka »Zasebnost« in
      kliknite gumb »Nastavitve vsebine«. 
      <br /> 3. Izberite »Dovoli nastavljanje lokalnih podatkov«.
      <br />
      <br />
      <strong>Microsoft Internet Explorer 6.0, 7.0, 8.0, 9.0 </strong>
      <br /> 1. Kliknite »Orodja« na vrhu vašega brskalnika in izberite »Internetne možnosti«, nato
      kliknite zavihek »Zasebnost«. 
      <br /> 2. Preverite, da je raven vaše zasebnosti nastavljena na srednjo ali manj, kar bo
      omogočilo uporabo piškotkov v vašem brskalniku. 
      <br /> 3. Nastavitev nad srednjo ravnijo bo onemogočila uporabo piškotkov.
      <br />
      <br />
      <strong>Mozilla Firefox </strong>
      <br /> 1. Kliknite »Orodja« na vrhu vašega brskalnika in izberite »Možnosti«. &gt;
      <br /> 2. Nato izberite ikono »Zasebnost«. &gt;
      <br /> 3. Kliknite »Piškotki« in izberite možnost »Dovoli stranem, da ustvarijo piškote«.
      <br />
      <br />
      <strong>Safari </strong>
      <br /> 1. Kliknite ikono zobnika na vrhu vašega brskalnika in izberite možnost »Nastavitve«. 
      <br /> 2. Kliknite »Zasebnost«, nato označite možnost »Onemogoči uporabo piškotkov tretjih
      oseb in oglaševalnih piškotkov«. 
      <br /> 3. Kliknite »Shrani«.
      <br />
      <br />
      <strong>Kako upravljati piškotke na računalniku Mac </strong>
      <br /> Če želite omogočiti piškotke naše spletne strani, sledite spodnjim korakom.
      <br />
      <br />
      <strong>icrosoft Internet Explorer 5.0 on OSX </strong>
      <br /> 1. Kliknite »Explorer« na vrhu vašega brskalnika in izberite možnost »Nastavitve«. 
      <br /> 2. Pomaknite se navzdol do »Piškotki« v razdelku »Prejete datoteke«. 
      <br /> 3. Izberite možnost »Nikoli ne vprašaj«.
      <br />
      <br />
      <strong>Safari on OSX </strong>
      <br /> 1. Kliknite »Safari« na vrhu vašega brskalnika in izberite možnost »Nastavitve«. 
      <br /> 2. Kliknite »Zasebnost« in nato »Omogoči uporabo piškotkov«. 
      <br /> 3. Izberite »Samo na obiskanih straneh«.
      <br />
      <br />
      <strong>Mozilla and Netscape on OSX </strong>
      <br /> 1. Kliknite »Mozilla« ali »Netscape« na vrhu vašega brskalnika in izberite možnost
      »Nastavitve«.
      <br /> 2. Pomaknite se navzdol do »Piškotki« v razdelku »Zasebnost in varnost«. 
      <br /> 3. Izberite »Dovoli piškotke samo za izvorno spletno stran«.
      <br />
      <br />
      <strong>Opera</strong>
      <br /> 1. Kliknite »Meni« na vrhu vašega brskalnika in izberite »Nastavitve«. 
      <br /> 2. Nato izberite »Možnosti« in zavihek »Napredno«. 
      <br /> 3. Izberite možnost »Omogoči uporabo piškotkov«.
      <br />
      <br />
    </p>
  )
}

function enCookiesContent() {
  return (
    <div>
      <p>
        In order to ease your use of our website and to make it more user-friendly, we use cookies.
        Cookies are small data units which allow us to compare new and past visitors and to
        understand how they use our website, at the same time providing us with data with which we
        can make future visits to the website more user-friendly and efficient. Cookies do not
        register any personal data of the user and do not store information on the basis of which
        you could be identified. If you do not wish to use cookies, you should use your computer
        settings so that all cookies of websites get deleted and/or you get a notification before a
        cookie is saved onto your disk. If you do not wish to change the use of cookies, simply
        continue onto the website.
      </p>
      <p>
        For more information about cookies and their impact on you and your surfing the web, please
        visit the following website: www.aboutcookies.org.
      </p>
      <p>&nbsp;</p>
      <p>TYPES OF COOKIES WE USE</p>
      <p>Mandatory cookies</p>
      <p>
        These cookies are mandatory for browsing a website and using its features, such as access to
        safe areas of the website. Without these cookies, services you demand, e.g., prize draw
        form, etc., cannot be provided.
      </p>
      <p>Performance cookies</p>
      <p>
        These cookies collect information regarding the way a website is used, e.g., which sites the
        users most frequently visit and whether error messages occur on such websites. These cookies
        do not collect data on the basis of which users could be identified. All collected data is
        joined and therefore anonymised. These cookies are used only to improve the functioning of a
        website.
      </p>
      <p>By using our website you agree that we can install this type of cookies on your device.</p>
      <p>Functional cookies</p>
      <p>
        These cookies ensure that a website remembers your selected options (e.g., your user name,
        language or region/location), therefore offering you improved and more personalised options.
        These cookies also remember font and font size changes as well as changes of other parts of
        a website you can modify. They are useful to ensure services you demand, e.g., playing a
        video or commenting on a blog. Information, stored by these cookies, may be anonymised and
        your browsing across other websites is not tracked.
      </p>
      <p>By using our website you agree that we can install this type of cookies on your device.</p>
      <p>How to manage cookies on a PC</p>
      <p>If you want to enable cookies of our website, follow the steps below.</p>
      <p>&nbsp;</p>
      <p>Google Chrome</p>
      <ol>
        <li>Click "Tools" at the top of your browser and select "Settings".</li>
        <li>
          Click "Show additional settings", move toward section "Privacy" and click on the button
          "Content settings".
        </li>
        <li>Select "Allow setting of local data".</li>
      </ol>
      <p>&nbsp;</p>
      <p>Microsoft Internet Explorer 6.0, 7.0, 8.0, 9.0</p>
      <ol>
        <li>
          Click "Tools" at the top of your browser and select "Internet options". Then click on the
          tab "Privacy".
        </li>
        <li>
          Check whether the level of your privacy is set to medium or low, which enables the use of
          cookies in your browser.
        </li>
        <li>Setting above the medium level disables the use of cookies.</li>
      </ol>
      <p>&nbsp;</p>
      <p>Mozilla Firefox</p>
      <ol>
        <li>Click "Tools" at the top of your browser and select "Options".</li>
        <li>Then select the icon "Privacy".</li>
        <li>Click "Cookies" and select the option "Allow websites to create cookies".</li>
      </ol>
      <p>&nbsp;</p>
      <p>Safari</p>
      <ol>
        <li>Click the gear icon at the top of your browser and select "Settings."</li>
        <li>
          Click "Privacy", then tick the option "Disable the use of third-party cookies and
          advertising cookies".
        </li>
        <li>Click "Save".</li>
      </ol>
      <p>&nbsp;</p>
      <p>How to manage cookies on Mac</p>
      <p>If you want to enable cookies on our website, follow the steps below.</p>
      <p>&nbsp;</p>
      <p>Microsoft Internet Explorer 5.0 on OSX</p>
      <ol>
        <li>Click "Explorer" at the top of your browser and select "Settings".</li>
        <li>Slide down to "Cookies" in section "Received files".</li>
        <li>Select "Never ask".</li>
      </ol>
      <p>&nbsp;</p>
      <p>Safari on OSX</p>
      <ol>
        <li>Click "Safari" at the top of your browser and select "Settings".</li>
        <li>Click "Privacy" and then "Enable the use of cookies".</li>
        <li>Select "Only on visited websites".</li>
      </ol>
      <p>&nbsp;</p>
      <p>Mozilla and Netscape on OSX</p>
      <ol>
        <li>Click "Mozilla" or "Netscape" at the top of your browser and select "Settings".</li>
        <li>Slide down to "Cookies" in the section "Privacy and security".</li>
        <li>Select "Allow cookies only for the source website".</li>
      </ol>
      <p>&nbsp;</p>
      <p>Opera</p>
      <ol>
        <li>Click "Menu" at the top of your browser and select "Settings".</li>
        <li>Then select "Options" and the tab "Advanced".</li>
        <li>Select "Enable the use of cookies".</li>
      </ol>
      <p>&nbsp;</p>
    </div>
  )
}
